
export default {
  name: 'TopBar',
  components: {
    Wrapper: () => import('@/components/Structure/Wrapper')
  },
  computed: {
    cssProps() {
      return {
        '--bg-color': this.bgColors,
        '--txt-color': this.txtColors
      }
    }
  },
  props: {
    topbar: {
      type: Array,
      default() {}
    },
    bgColors: {
      type: String,
      default: '#FFFFFF'
    },
    txtColors: {
      type: String,
      default: '#000000'
    }
  },
  data() {
    return {
      swiperOption: {
        autoplay: true,
        autoplayDisableOnInteraction: false,
        pagination: false,
        effect: 'fade'
      }
    }
  }
}
